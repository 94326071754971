// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-values-js": () => import("./../src/pages/about/values.js" /* webpackChunkName: "component---src-pages-about-values-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-x-js": () => import("./../src/pages/index-x.js" /* webpackChunkName: "component---src-pages-index-x-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-design-js": () => import("./../src/pages/services/design.js" /* webpackChunkName: "component---src-pages-services-design-js" */),
  "component---src-pages-services-index-js": () => import("./../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-mobile-js": () => import("./../src/pages/services/mobile.js" /* webpackChunkName: "component---src-pages-services-mobile-js" */),
  "component---src-pages-services-strategy-js": () => import("./../src/pages/services/strategy.js" /* webpackChunkName: "component---src-pages-services-strategy-js" */),
  "component---src-pages-services-web-js": () => import("./../src/pages/services/web.js" /* webpackChunkName: "component---src-pages-services-web-js" */)
}

